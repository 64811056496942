import React, { Fragment } from 'react';
import { Provider } from 'react-redux';
import Sticky from 'react-stickynode';
import { ThemeProvider } from 'styled-components';
import { cryptoTheme } from 'common/src/theme/crypto';
import { ResetCSS } from 'common/src/assets/css/style';
import {
  GlobalStyle,
  ContentWrapper,
} from '../../containers/Crypto/crypto.style';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';
import NavbarNoHamburgerNoButtons from '../../containers/Crypto/NavbarNoHamburgerNoButtons';
import Banner from '../../containers/Crypto/BannerSectionHelocLandingPage';
import TestimonialSlider from '../../containers/Crypto/TestimonialSlider';
//import CompareHelocOptions from '../containers/Crypto/CompareHelocOptions';
import CompareHelocOptions from '../../containers/Crypto/CompareHelocCashOut';
/* import Testimonial from '../containers/Crypto/Testimonial'; */
import Transactions from '../../containers/Crypto/Transaction';
import Reviews from '../../containers/Crypto/ReviewPage';
import MillionsServedHELOC from '../../containers/Crypto/MillionsServedHELOC';
import Footer from '../../containers/Crypto/Footer';
import SEO from '../../components/seo';
import store from '../../store';

const helocLandingPage = () => {
  return (
    <ThemeProvider theme={cryptoTheme}>
      <Provider store={store}>
        <Fragment>
          <SEO title="Best Finance | Simple, Online, Fast" />
          <ResetCSS />
          <GlobalStyle />
          <ContentWrapper>
            <Sticky
              top={0}
              innerZ={9999}
              inactiveClass="sticky-nav-active"
              activeClass="sticky-nav-active"
            >
              <DrawerProvider>
                <NavbarNoHamburgerNoButtons />
              </DrawerProvider>
            </Sticky>
            <Banner />
            {/* <MillionsServed /> */}
            {/* <TestimonialSlider /> */}
            {/*  <Transactions /> */}
            {/*  <Reviews /> */}
            {/* <CompareHelocOptions /> */}

            {/* <LandingPageButtons /> */}
            {/*  <MagazineLogos /> */}
            {/* <MagazineLogosCopy /> */}

            {/*  <LoanCard /> */}
            {/*   <ControlSections /> */}

            {/* <AppVideo /> */}
            {/* <BetaSection/> */}

            {/* <Testimonial /> */}
            <Footer />
          </ContentWrapper>
        </Fragment>
      </Provider>
    </ThemeProvider>
  );
};

export default helocLandingPage;
