import React, { useState } from 'react';
import { connect } from 'react-redux';
//import Link from 'gatsby';
//import Link from 'common/src/components/Link';
import { navigate } from 'gatsby';
import { Icon } from 'react-icons-kit';
import { checkmarkCircled } from 'react-icons-kit/ionicons/checkmarkCircled';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Button from 'common/src/components/Button';
import Container from 'common/src/components/UI/Container';
import { SectionHeader } from '../appModern.style';
import SectionWrapper, {
  ButtonGroup,
  PricingArea,
  InnerWrapper,
  PricingCard,
} from './pricingPolicy.style';
import { updateUserInfo } from '../../../actions';
import BannerWrapper, {
  BgImageWrapper,
} from '../BannerSection/bannerSection.style';
import BannerBG from 'common/src/assets/image/crypto/shutterstock_1106309045.jpg';

import crown from 'common/src/assets/image/appModern/crown.svg';

import { pricing } from 'common/src/data/Crypto';
import { alignItems } from 'styled-system';

const LoanCard = ({ currentUser }) => {
  const { slogan, title, monthly, annualy } = pricing;
  const [state, setState] = useState({
    active: 'monthly',
    pricingPlan: monthly,
  });

  const goToBuy = async (e) => {
    navigate('/buy-pages/buy-process/');
  };

  const goToRefi = async (e) => {
    navigate('/refi-pages/refinance-reasons/');
  };

  return (
    <SectionWrapper id="pricing">
      <Container className="containerClass">
        <PricingArea>
          <InnerWrapper>
            {/*{state.pricingPlan.map((item) => ( */}
            <PricingCard>
              <div className="card-header">
                <Heading as="h3" content={`Millions `} />
                <Text content={`in equity unlocked`} />
              </div>
            </PricingCard>
            <PricingCard>
              <div className="card-header">
                <Heading as="h3" content={`100's`} />
                <Text content={`of members across the US`} />
              </div>
            </PricingCard>
            {/*{state.pricingPlan.map((item) => ( */}
            <PricingCard>
              <div className="card-header">
                <Heading as="h3" content={` 100% `} />
                <Text content={`Online application`} />
              </div>
            </PricingCard>
          </InnerWrapper>
        </PricingArea>
      </Container>
    </SectionWrapper>
  );
};

const mapStateToProps = ({ root: { currentUser } }) => ({
  currentUser: currentUser?.user,
  userToken: currentUser?.token,
});

const mapDispatchToProps = (dispatch) => {
  return {
    updateUserInfo: (currentUser, userToken) =>
      dispatch(updateUserInfo(currentUser, userToken)),
  };
};

const LoanCardRedux = connect(mapStateToProps, mapDispatchToProps)(LoanCard);

export default LoanCardRedux;
